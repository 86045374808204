import React from "react";
import { graphql } from "gatsby";

import * as css from "../styles/Articles.module.scss";

import Layout from "../components/Layout";
import ArticleButton from "../components/ArticleButton";

import I18n from "../components/I18n";

const Articles = ({
  location: { pathname },
  pageContext: { locale, locales },
  data: { strapi: { theme = {}, articles: { data: articles = [] } } = {} },
}) => {
  return (
    <Layout
      theme={theme}
      pathname={pathname}
      className={css.Articles}
      locale={locale}
      locales={locales}
    >
      <I18n id="title.news" component="h1" />
      {articles.map(({ id, attributes }) => (
        <ArticleButton key={id} {...attributes} />
      ))}
    </Layout>
  );
};

export const query = graphql`
  query {
    strapi {
      articles(sort: "publication_date:desc") {
        data {
          id
          attributes {
            title
            description
            source
            url: link
            image {
              data {
                id
                attributes {
                  url
                  formats
                  fileS3(minFormat: "small") {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1
                        quality: 90
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Articles;
