import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as css from "../styles/ArticleButton.module.scss";
import ButtonBase from "../components/ButtonBase";

import Youtube from "@mdi/svg/svg/youtube.svg";
import Twitter from "@mdi/svg/svg/twitter.svg";
import Facebook from "@mdi/svg/svg/facebook.svg";
import Instagram from "@mdi/svg/svg/instagram.svg";
import Vimeo from "@mdi/svg/svg/vimeo.svg";
import ArticleIcon from "@mdi/svg/svg/align-horizontal-left.svg";
import { classs } from "../utils/utils";

const Icon = ({ source, className }) => {
  const icons = {
    twitter: Twitter,
    facebook: Facebook,
    instagram: Instagram,
    vimeo: Vimeo,
    youtube: Youtube,
    article: ArticleIcon,
  };
  const Source = icons[source] ?? icons.article;
  return <Source className={className} />;
};

const ArticleButton = ({
  className,
  url,
  image,
  source,
  title,
  description,
}) => (
  <article className={classs(css.ArticleButton, className)}>
    <ButtonBase component="a" href={url} target="_blank" rel="noreferrer">
      <GatsbyImage image={getImage(image?.data?.attributes?.fileS3)} alt="" />
      <Icon className="source" source={source} />
    </ButtonBase>
    <h3>{title}</h3>
    <p>{description}</p>
  </article>
);

export default ArticleButton;
